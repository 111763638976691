<template>
  <div class="req-temp-container" v-if="readyToRender">
    <vs-card class="mt-6">
      <div slot="header">
        <div class="flex items-center">
          <h3 class="font-medium">Templates & hosted payment pages</h3>
        </div>
      </div>
      <div class="flex justify-end">
        <vs-button flat v-round class="only-border-btn" size="medium" type="border" @click="configureTemplate('pay-now')" v-if="hasPayNow">
          <plus-icon size="1.5x" class="button-icon"></plus-icon>pay now
        </vs-button>

        <vs-button flat v-round class="only-border-btn ml-3" size="medium" type="border" @click="configureTemplate('pay-later')" v-if="hasProduct('THREE_PARTY')">
          <plus-icon size="1.5x" class="button-icon"></plus-icon>pay later
        </vs-button>

        <vs-button flat v-round class="only-border-btn ml-3" size="medium" type="border" @click="configureTemplate('direct')" v-if="hasProduct('TWO_PARTY')">
          <plus-icon size="1.5x" class="button-icon"></plus-icon>direct request
        </vs-button>
      </div>

      <vs-table :data="paymentRequestTemplates" class="mt-5 stripes">
        <template slot="thead">
          <vs-th>ID</vs-th>
          <vs-th>Template</vs-th>
          <vs-th class="custom-center">Payment request</vs-th>
          <vs-th class="custom-center">Hosted payment page</vs-th>
          <vs-th>Disbursement account</vs-th>
          <vs-th></vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="index" v-for="(tr, index) in data">
            <vs-td v-if="displayTemplate(tr)" :data="tr.requestTemplateId">
              {{ tr.requestTemplateId }}
            </vs-td>
            <vs-td v-if="displayTemplate(tr)" :data="tr.templateName">
              {{ tr.templateName }}<br />
              <span class="text-xs" v-html="paymentRequestType(tr)"></span>
            </vs-td>
            <vs-td v-if="displayTemplate(tr)" :data="tr.paymentOptions" class="flex justify-center">
              <vs-switch v-model="tr.displayOnDashboard" @click="handleChangeDisplay(tr._id, !tr.displayOnDashboard)" :disabled="isTwoParty(tr) || !showHPPView(tr)" />
            </vs-td>
            <vs-td v-if="displayTemplate(tr)" :data="tr" class="text-center">
              <a @click="handleClickHPP(tr.requestTemplateId)" v-if="showHPPView(tr)">View</a>
            </vs-td>
            <vs-td v-if="displayTemplate(tr)" :data="tr">
              <span v-html="getBankDetails(tr.bank)"></span>
            </vs-td>
            <vs-td v-if="displayTemplate(tr)" :data="tr">
              <a class="pl-5 pr-5" @click="editTemplate(tr)">Edit</a>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vs-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { PlusIcon } from "vue-feather-icons";

export default {
  components: { PlusIcon },
  data() {
    return {
      enableConfigure: false,
      hppBaseUrl: process.env.VUE_APP_HPP_BASE_URL,
      partnerRequestOptions: [],
      paymentRequestTemplates: [],
      plans: [],
      readyToRender: false,
      templateType: "",
    };
  },

  computed: {
    validateForm() {
      return !this.errors.any();
    },

    user() {
      return this.$store.state.AppActiveUser;
    },

    partnerId() {
      return this.user.userType === "admin" ? this.user._id : this.user.partnerId;
    },

    hasPayNow() {
      return this.partnerRequestOptions && this.partnerRequestOptions.includes("pay-now");
    }
  },

  mounted() {
    this.checkLogin().then((result) => {
      this.partnerRequestOptions = result.data.data.requestOptions;
      this.plans = result.data.data.plans;
      this.getRequestTemplateByMerchantId();
      this.readyToRender = true;
    });
  },

  methods: {
    ...mapActions("admin", ["checkLogin"]),
    ...mapActions("paymentRequestTemplate", ["fetchRequestTemplateByMerchantId", "updateDisplayOnDashboard"]),
    ...mapActions("paymentRequest", ["fetchPaymentRequestListByReqTemId"]),

    async handleChangeDisplay(id, value) {
      const payload = {
        id: id,
        displayOnDashboard: value
      };

      this.$vs.loading();
      await this.updateDisplayOnDashboard(payload).then(result => {
        this.$vs.notify({
          title: "Template",
          text: "Updated successfully",
          color: "success",
          iconPack: "feather",
          icon: "icon-check-circle",
          position: "top-right",
        });
        this.$vs.loading.close();
      }).catch(ex => {
        this.$vs.notify({
          title: "Template",
          text: ex.response.data.message,
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
          position: "top-right",
        });
        this.$vs.loading.close();
      })
    },

    async getRequestTemplateByMerchantId() {
      let data = {
        merchantId: this.partnerId,
        type: "template",
      };

      this.$vs.loading();

      await this.fetchRequestTemplateByMerchantId(data).then((res) => {
        this.paymentRequestTemplates = res.data.data;
        this.$vs.loading.close();
      }).catch(ex => {
        this.$vs.notify({
          title: "Template",
          text: ex.response.data.message,
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
          position: "top-right",
        })
        this.$vs.loading.close();
      });
    },

    editTemplate(template) {
      let templateConfig = "pay-now";

      if (template.product) {
        templateConfig = (template.product.productConfigType == "THREE_PARTY") ? "pay-later" : "direct";
      }

      this.$router.push({
        name: "payment-request-template-edit",
        params: { id: template._id, templateType: templateConfig },
      });
    },

    activateTemplate(el) {
      this.templateType = el;
      this.enableConfigure = true;
    },

    configureTemplate(el) {
      this.$router.push({
        name: "payment-request-template-create",
        params: { templateType: el },
      });
    },

    configureCancel() {
      this.templateType = "";
      this.enableConfigure = false;
    },

    paymentRequestType(val) {
      let type = "";

      const paymentOption = val.paymentOptions[0];

      if (paymentOption && paymentOption.requestType == "recurring") {
        type = "Recurring";
      } else if (paymentOption && paymentOption.requestType == "one-off") {
        if (paymentOption.requestOptions.includes("pay-later")) {
          type = paymentOption.payLaterPlan.length ? this.getPlanName(paymentOption.payLaterPlan[0], val) : "Pay Later";
        } else if (paymentOption.requestOptions.includes("pay-now")) {
          type = "Pay now";
        }
      }

      return type;
    },

    getPlanName(id, template) {
      const objIndex = this.plans.findIndex((obj) => obj.customPlanId === id);
      return (objIndex >= 0) ? `${this.plans[objIndex].productName} <br /> ${this.plans[objIndex].customPlanId} (${this.getType(template)})` : "";
    },

    hasProduct(type) {
      const plans = this.plans.filter((item) => item.productConfigType == type);
      return plans.length;
    },

    getBankDetails(bank) {
      let bankName = "";

      if (bank) {
        bankName =  bank.displayName || bank.accountName;
        bankName = `${bankName} <br />(BSB: ${this.maskBsb(bank.bsb)}, Acc: ${bank.accountNumber})`;
      }

      return bankName;
    },

    maskBsb(string) {
      if (!string.includes("-")) {
        let arr = string.replace(" ", "").split("");
        arr.splice(3, 0, "-");
        string = arr.join("");
      }

      return string;
    },

    displayTemplate(data) {
      return (data.status !== "Archived");
    },

    handleClickHPP(id) {
      const templateId = id.replace("RT-", "");
      const url = `${this.hppBaseUrl}${templateId}`;
      window.open(url, "_blank");
    },

    getType(template) {
      let type = null;

      if (template.templateType == "api") {
        return "";
      }

      if (template.paymentOptions[0].requestOptions[0] == "pay-now") {
        type = "Pay now";
      } else {
        if (template.product && template.product.productConfigType == "THREE_PARTY") {
          type = "pay later";
        } else {
          type = "direct request";
        }
      }

      return type;
    },

    isTwoParty(template) {
      return (template && template.product && template.product.productConfigType == "TWO_PARTY");
    },

    showHPPView(template) {
      return !this.isTwoParty(template) && (template && template.product && template.product.disburseTo == "external" ? false : true);
    }
   },
};
</script>

